import moment from "moment";

function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
}

export function formatDate(date, format) {
    const isValid = isDateValid(date);
    if (!isValid) return date;
    return moment(date).format(format);
}