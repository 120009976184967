<template>
  <div style="background-color: rgb(245,245,245)" class="min-h-screen relative overflow-hidden">
    <success-notification :show="$store.state.ui.success"/>
    <error-notification :show="$store.state.ui.error" :message="$store.state.ui.error_message"/>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
<!--            <div @click="set_home_page(true)"  class=" cursor-pointer">-->
<!--              <new-logo/>-->
<!--            </div>-->
<!--            <div class="flex-shrink-0 flex items-center px-4">-->
<!--              <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-base-600-mark-gray-800-text.svg" alt="Workflow" />-->
<!--            </div>-->
            <div class="mt-5 flex-1 h-0 overflow-y-auto">

              <nav class="px-2 space-y-1">
                <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                </a>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <sidebar @onToggle="setLogo" @isShowSidebar="isShowSidebar = $event"/>

    <div class="flex flex-col flex-1">
      <div class="fixed w-full top-0 z-50 h-24 md:pt-0 bg-gray-550">
<!--        <router-link to="/" @click="set_home_page(true)"  class=" cursor-pointer fixed z-40 top-8 lg:top-7">-->
<!--          <img src="/logo2.png" class="w-24 h-12 xl:w-28 xl:h-14 ml-5" :class="open ? 'transform translate-x-0 animation duration-500 ease-in-out' : 'transform -translate-x-40 animation duration-500 ease-in-out'">-->
<!--          <img src="/logo1.png" class="w-12 h-12 md:w-12 md:h-12 ml-11">  &lt;!&ndash; marting top dodati &ndash;&gt;-->
<!--        </router-link>-->
        <router-link to="/" @click="back_to_home"  class="cursor-pointer hidden lg:block">
          <img src="/logo2.png" :class="`fixed z-40 top-8 lg:top-7 w-24 h-12 xl:w-28 xl:h-14 ml-2 md:ml-3.5 lg:ml-3.5 xl:ml-5 animation duration-300 ease-in-out ${open ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`">
          <img src="/logo1.png" :class="`fixed z-40 top-8 lg:top-7 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 animation duration-300 ease-in-out ${!open ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`">
        </router-link>
        <router-link to="/" @click="back_to_home"  class="cursor-pointer lg:hidden">
<!--          <img src="/logo1.png" :class="`fixed z-40 top-8 lg:top-7 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 animation duration-300 ease-in-out`">-->
          <img src="/white-logo-h.png" :class="`fixed z-40 top-9 lg:top-7 w-9 h-9 md:w-10 md:h-10 ml-6 md:ml-11 xl:ml-11 animation duration-300 ease-in-out`">
        </router-link>
        <!-- Header plavi deo -->
        <div :class="`max-w-7xl mx-auto px-4 md:px-8 lg:pl-28 xl:mx-auto`">
<!--          Prva linija-->
          <div class="w-full flex py-2">
            <div class="flex-1 flex justify-between">
              <div class="items-center w-full lg:ml-4 xl:ml-0">

                <!--  -->
                <Menu id="header-items-space" as="div" class="h-16 py-9 bg-gradient-to-r from-base-500 to-base-600 shadow-xl mx-auto rounded-xl px-6 mt-3 lg:mt-2 z-50">

                  <div class="flex items-center justify-end h-full gap-x-4 font-semibold">
                    <div class="rounded flex items-start gap-x-1 w-36 h-10 mr-1">



                      <Menu as="div" class="relative w-1/3 text-left">
                        <div class="transform scale-95 hover:scale-110 transition duration-200 ease-in-out">
                          <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 relative pl-2">
                            <div v-if="$store.state.auth.user.cart.length" class="flex items-center justify-center -top-3 -right-3 absolute bg-base-600 border border-white w-5 h-5 text-sm text-center text-white rounded-full align-middle">
                              <p class="leading-none font-bold">{{$store.state.auth.user.cart.length}}</p>
                            </div>
<!--                            <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />-->
                             <font-awesome-icon icon="fa-solid fa-cart-shopping" class="h-6 w-6 text-white" />
                          </MenuButton>
                        </div>

                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                          <MenuItems ref="cart" class="origin-top-right absolute -right-44 mr-1 md:mr-0 md:-right-72 lg:-right-10 xl:right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                            <div class="p-2">

                              <div v-if="totalAmountWithDiscount" class="text-gray-600 font-medium">
                                <p class="border-b pb-3 pt-2 text-black text-lg">{{loc.your_cart}}</p>
                                <p class="mt-2">{{ loc.order_summary }} ({{user.cart.length}})</p>
                                <div class="flex items-start justify-between mt-3">
                                  <div>
                                    <dt>{{loc.total}}</dt>
                                    <div class="transform scale-90 -translate-x-2 -translate-y-2">
                                      <small class="text-xs text-gray-400">{{ loc.vat_is_included }}</small>
                                    </div>
                                  </div>
                                  <p v-if="totalAmountWithDiscount">{{ monetary(totalAmountWithDiscount, user.currency) }}</p>
                                  <p v-else-if="totalAmountWithDiscount === 0">{{ monetary(0, user.currency) }}</p>
                                  <p v-else>{{ loc.processing }}...</p>
                                </div>


                                <div class="flex items-start justify-between font-normal text-base-600 pb-3">
                                  <p class="font-normal">{{loc.promotional_saving}}</p>
                                  <p>{{ monetary(totalDiscount, user.currency) }}</p>
                                </div>
                              </div>

                              <MenuItem as="div">
                                <button @click="$router.push('/cart');" :class="`w-full border border-transparent bg-base-600 hover:bg-base-500 transition duration-200 ease-in-out rounded-md shadow-sm py-2 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500`">
                                  {{loc.prev_cart}}
                                </button>
                              </MenuItem>

                              <div class="overflow-y-auto max-h-72 mt-4 pt-4">
                                <MenuItem as="div" v-slot="{ active }" v-for="item in user.cart">
                                  <div @click="$router.push(`/benefit/${item.benefit.id}`);" class="py-4 px-1 border-t last:border-b cursor-pointer group hover:bg-gray-50 text-gray-600">
                                    <div class="w-full flex items-start gap-x-1">
                                      <div class="w-20">
                                        <img v-if="item.benefit.pictures.length" :src="item.benefit.pictures[0].image" class="w-full h-12 rounded-lg">
                                      </div>
                                      <div class="w-3/4 text-sm transform scale-90 -translate-x-1 -translate-y-1 group-hover:text-base-600">
                                        <p class="ml-1">{{item.benefit.name}}</p>
                                        <div class="flex items-center gap-x-2 mt-1">
                                          <p v-if="item.benefit?.discount" class="">{{ monetary(item.benefit.price * (100 - item.benefit.discount) / 100 * (item.count), user.currency) }}</p>
                                          <p v-if="item.benefit?.discount" class=" line-through text-base-600">{{ monetary(item.benefit.price * (item.count), user.currency) }}</p>
                                          <p v-if="!item.benefit?.discount" class="font-medium">{{ monetary(item.benefit.price * (item.count), user.currency) }}</p>
                                          <!--                                          <p>{{ monetary(item.benefit.price * (100 - item.benefit.discount) / 100, item.benefit.currency)}}</p>-->
                                        </div>
                                      </div>
                                    </div>
                                    <div class="flex justify-between items-end mt-3">
                                      <div class="flex items-center justify-around text-base-600 font-medium h-7 w-20 rounded border border-base-600 hover:bg-white md:ml-auto lg:ml-0">
                                        <button :disabled="loading" class="w-1/3 h-8 rounded-l text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click.stop="item.count > 1 ? item.count-- : item.count; changeCartItemCount(item.count, item.id);">-</button>
                                        <p class="w-1/3 text-center">{{ item.count }}</p>
                                        <button :disabled="loading" class="w-1/3 h-8 rounded-r text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click.stop="item.count++; changeCartItemCount(item.count, item.id);">+</button>
                                      </div>
                                      <button @click.stop="toggle_cart(item.benefit.id)" class="text-red-600 flex items-end mr-1">
                                        <font-awesome-icon icon="fa-solid fa-times" class="h-6 w-6" />
                                      </button>
                                    </div>
                                  </div>
                                </MenuItem>
                              </div>

                              <div class="flex flex-col items-center justify center" v-if="!$store.state.auth.user.cart.length">
                                <font-awesome-icon icon="fa-solid fa-cart-shopping" class="h-14 w-14" />
                                <p class="mx-4 mb-5 mt-2">{{loc.cart_empty}}</p>
                              </div>

<!--                              <div class="flex p-2" v-if="$store.state.auth.user.cart.length">-->
<!--                                <button @click="$router.push('/cart')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">{{loc.prev_cart}}</button>-->
<!--                                <p class="ml-auto">{{monetary($store.state.auth.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100, 0), $store.state.auth.user.currency)}}</p>-->
<!--                              </div>-->
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>

                      <Menu as="div" class="w-1/3 relative pl-2">
                        <div class="transform scale-95 hover:scale-110 transition duration-200 ease-in-out">
                          <MenuButton class="flex items-center text-gray-400 hover:text-gray-600 relative">
                            <div v-if="$store.state.auth.user.favorite.length" class="flex items-center justify-center -top-3 -right-3 absolute bg-red-600 border border-white w-5 h-5 text-sm text-center text-white rounded-full z-5 align-middle font-medium">
                              <p class="leading-none">{{$store.state.auth.user.favorite.length}}</p>
                            </div>
<!--                            <HeartIcon class="h-6 w-6" aria-hidden="true" />-->
                            <font-awesome-icon icon="fa-solid fa-heart" class="h-6 w-6 text-white" />
                          </MenuButton>
                        </div>

                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                          <MenuItems class="origin-top-right absolute -right-32 mr-2 md:mr-0 md:-right-72 lg:-right-10 xl:right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                            <div class="p-2">
                              <p class="border-b pb-3 pt-2 text-black text-lg">{{ loc.favorites }}</p>

                              <div class="flex flex-col items-center justify-center h-48" v-if="!$store.state.auth.user.favorite.length">
                                <font-awesome-icon icon="fa-solid fa-heart" class="h-14 w-14" />
                                <p class="mx-10 text-sm text-center mt-3 text-black">{{loc.no_favs}}</p>
                              </div>

                              <div class="overflow-y-auto max-h-96">
                                <MenuItem as="div" v-slot="{ active }" v-for="item in $store.state.auth.user.favorite">
                                  <div @click="$router.push(`/benefit/${item.id}`)" class="py-4 px-1 border-b cursor-pointer group hover:bg-gray-50 text-gray-600">
                                    <div class="w-full flex items-start gap-x-1">
                                      <div class="w-20">
                                        <img v-if="item.pictures.length" :src="item.pictures[0].image" class="w-full h-12 rounded-lg">
                                      </div>
                                      <div class="w-3/4 text-sm transform scale-90 -translate-x-1 -translate-y-1 group-hover:text-base-600">
                                        <p class="ml-1">{{item.name}}</p>
                                        <div v-if="item.price > 0" class="flex items-center gap-x-2 mt-1">
                                          <p v-if="item?.discount" class="">{{ monetary(item.price * (100 - item.discount) / 100, user.currency) }}</p>
                                          <p :class="`${item?.discount ? 'line-through text-yellow-500' : ''}`">{{ monetary(item.price, user.currency) }}</p>
                                          <!--                                          <p>{{ monetary(item.benefit.price * (100 - item.benefit.discount) / 100, item.benefit.currency)}}</p>-->
                                        </div>
                                      </div>
                                      <button class="w-5 text-gray-600 hover:text-red-600 transition duration-200 ease-in-out" @click.stop="$store.dispatch('auth/toggleFavorites', item.id)">
                                         <font-awesome-icon icon="fa-solid fa-xmark" class="text-red-600" />
                                      </button>
                                    </div>
                                  </div>
                                </MenuItem>
                              </div>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>

                      <button @click="notsModal = true;" class="relative inline-block text-left focus:outline-none pl-2 transform scale-95 hover:scale-110 transition duration-200 ease-in-out">
                        <div class="rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">
                          <div v-if="$store.state.auth.user.new_notifications" class="flex items-center justify-center -top-3 -right-2.5 absolute bg-red-600 border border-white w-5 h-5 text-sm font-medium text-center text-white rounded-full align-middle">
                            <p class="leading-none font-medium">{{$store.state.auth.user.new_notifications}}</p>
                          </div>
                          <font-awesome-icon icon="fa-solid fa-bell" :class="`${$store.state.auth.user.new_notifications ? 'fa-shake' : ''} h-6 w-6 text-white`" />
                        </div>
                      </button>

<!--                      <Menu as="div" class="relative inline-block text-left pl-2">-->
<!--                        <div class="transform scale-95 hover:scale-110 transition duration-200 ease-in-out">-->
<!--                          <MenuButton @click="preview_notifications" class="rounded-full flex items-center text-gray-400 hover:text-gray-600 relative">-->
<!--                            <div v-if="$store.state.auth.user.new_notifications" class="flex items-center justify-center -top-3 -right-2.5 absolute bg-red-600 border border-white w-5 h-5 text-sm font-medium text-center text-white rounded-full align-middle">-->
<!--                              <p class="leading-none font-medium">{{$store.state.auth.user.new_notifications}}</p>-->
<!--                            </div>-->
<!--                            <font-awesome-icon icon="fa-solid fa-bell" :class="`${$store.state.auth.user.new_notifications ? 'fa-shake' : ''} h-6 w-6 text-white`" />-->
<!--                          </MenuButton>-->
<!--                        </div>-->

<!--                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">-->
<!--                          <MenuItems class="origin-top-right absolute -right-24 md:right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">-->
<!--                            <div class="p-2">-->
<!--                              <p class="border-b pb-3 pt-2 text-lg  ">{{ loc.notifications }}</p>-->

<!--                              <MenuItem v-if="!$store.state.auth.user.notifications.length">-->
<!--                                <div class="flex flex-col justify-center items-center py-4">-->
<!--                                  <BellIcon class="h-16 w-16" aria-hidden="true" />-->
<!--                                  <p class="text-center font-normal">{{loc.no_notifications}}</p>-->
<!--                                </div>-->
<!--                              </MenuItem>-->

<!--                              <div class="overflow-y-auto max-h-viewport">-->
<!--                                <MenuItem as="div" v-slot="{ active }" v-for="item in $store.state.auth.user.notifications" class="divider-y">-->
<!--                                  <div :class="`animation duration-200 ease-in-out py-2 mr-2 border-b cursor-pointer group hover:bg-gray-50 text-gray-600 ${!item.previewed ? 'bg-gray-200 text-base-600' : ''}`">-->
<!--                                    <div class="w-full flex items-start gap-x-1">-->
<!--                                      <div class="w-16 flex items-center justify-center text-3xl">-->
<!--                                        <i class="fa fa-comment-o text-4xl text-base-600" aria-hidden="true"></i>-->
<!--                                      </div>-->
<!--                                      <div class="w-3/4 text-sm transform scale-90 -translate-x-2 -translate-y-2">-->
<!--                                        <p class="group-hover:text-base-600">{{ item.text }}</p>-->
<!--                                        <div class="mt-2">-->
<!--                                          <p>{{ item.date }}</p>-->
<!--                                        </div>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </MenuItem>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </MenuItems>-->
<!--                        </transition>-->
<!--                      </Menu>-->

                    </div>






                    <div class="tooltip hidden md:block">
                      <div class="w-64 h-7 text-center mr-4 cursor-pointer">
                        <div class="progress2 progress-moved border-2 bg-gradient-to-r from-base-500 via-base-500 to-base-600 border-white">
                          <div
                            class="progress-bar2 bg-white rounded-xl"
                            :style="`width: ${total_budget == 0 ? total_budget: $store.state.auth.user.used}%`">
                          </div>
                        </div>
                      </div>
                      <div class="tooltip tooltiptext bg-white shadow-md border border-gray-300 text-gray-500 font-normal text-sm">
<!--                        <p class="text-left pl-4 font-semibold">{{loc.flex_budgets}}:</p>-->
                        <p class="text-left pl-3 font-semibold">{{loc.flex_benefit_budget}}: <span class="text-base-500">{{monetary($store.state.auth.user.base_balance, $store.state.auth.user.currency)}}</span></p>
                        <p class="text-left pl-3">{{loc.spent}}: <span class="text-yellow-500">{{monetary($store.state.auth.user.spent, $store.state.auth.user.currency)}}</span></p>
                        <p class="text-left pl-3">{{loc.current_balance}}: <span class="text-base-500">{{monetary($store.state.auth.user.balance, $store.state.auth.user.currency)}}</span></p>
                        <div v-if="$store.state.auth.user.category_budgets.length" class="pt-4">
                          <p class="text-left pl-3 font-semibold tracking-tighter">{{loc.category_budgets}}:</p>
                          <div v-for="b in $store.state.auth.user.category_budgets"
                               class="border-t py-1">
                            <p class="text-left pl-3 font-medium">{{loc.budget_for_category}}: <span class="text-base-500">{{ parse_name(b.category) }}</span></p>
                            <p class="text-left pl-3">{{loc.yearly_total_budget}}: <span class="text-base-500">{{monetary(b.amount, $store.state.auth.user.currency)}}</span></p>
                            <p class="text-left pl-3">{{loc.spent}}: <span class="text-yellow-500">{{ monetary(b.spent, $store.state.auth.user.currency) }}</span></p>
                            <p class="text-left pl-3">{{loc.current_balance}}: <span class="text-base-500">{{ monetary(b.amount - b.spent, $store.state.auth.user.currency) }}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <p class="hidden md:block text-white h-10">{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
                    <MenuButton class="text-sm flex flex-col items-center h-12 justify-end">
                      <img v-if="$store.state.auth.user.profile_picture" class="h-8 w-8 rounded-full border-2 border-white" :src="$store.state.auth.user.profile_picture" alt="" />
                      <user-icon class="text-base-500 w-6 h-8" v-else></user-icon>
                      <chevron-down-icon class="text-white w-4 h-4"></chevron-down-icon>
                      <!--                      <user-icon v-else />-->
                    </MenuButton>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                    </transition>
                    <MenuItems class="font-normal origin-top-right absolute top-16 mt-4 lg:mt-3 w-64 rounded-md shadow-lg bg-gray-550 ring-1 ring-black ring-opacity-5 focus:outline-none z-100">
                      <MenuItem v-slot="{ active }" class="cursor-pointer mt-1">
                        <div class="flex items-center pr-2 gap-x-2" v-if="$store.state.auth.user">
                          <img v-if="$store.state.auth.user.profile_picture" class="h-12 w-12 rounded-full mx-4 my-2" :src="$store.state.auth.user.profile_picture" alt="" />
                          <!--                          <img  class="h-8 w-8 rounded-full" :src="$store.state.auth.user.profile_picture" alt="" />-->
<!--                          <user-icon class="text-base-500 w-6 mx-4 my-2 transform scale-150" v-else></user-icon>-->
                          <svg v-else class="h-12 w-14 text-gray-300 border border-white rounded-full bg-white ml-2 my-2" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                          <p class="text-sm text-gray-600 pb-3 pt-1">{{$store.state.auth.user.position}}</p>
                        </div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div class="border-b border-gray-200 pb-2 flex justify-center">
                          <div @click="$router.push('/profile')" class=" w-5/6 text-base-500 inline-flex items-center px-2.5 py-1 border border-base-500 shadow-sm rounded-xl text-gray-700 bg-transparent hover:bg-base-600 hover:text-white text-center justify-center">
                            {{ loc.profile }}
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <p class="ml-4 font-bold text-black mt-2">{{ loc.account }}</p>
                      </MenuItem>
                      <MenuItem v-if="$store.state.auth.user" v-slot="{ active }" class="cursor-pointer">
                        <div @click.stop="change_language" class="flex items-center py-2 px-4 text-gray-700 hover:bg-base-600 hover:text-white ">
                          {{ $store.state.auth.user.language == "SR" ? "Language: " : "Jezik: " }}
                          <img
                            v-if="$store.state.auth.user.language == 'EN'"
                            class="rounded-full ml-4"
                            style="height: 20px; width: 20px"
                            src="/flags/Serbia.png"
                            alt="serbian flag"
                          />
                          <img
                            v-if="$store.state.auth.user.language == 'SR'"
                            class="rounded-full ml-4"
                            style="height: 20px; width: 20px"
                            src="/flags/United-States.png"
                            alt="usa flag"
                          />
                        </div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="open_card" class="block px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">{{ loc.my_card }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/transactions')" class="block px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.my_transactions }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/reimbursement')" class="block pb-2 px-4 pt-2 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.my_requests }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/subscriptions')" class="block pb-2 px-4 pt-2 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.my_subscriptions }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/suggestion')" class="block mb-2 py-2 px-4 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.benefit_suggestion }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer border-t border-gray-200 pt-2">
                        <p class="pl-4 font-bold text-black mt-2">{{ loc.help }}</p>
                      </MenuItem>

<!--                      <MenuItem v-slot="{ active }" class="cursor-pointer">-->
<!--                        <a class="block px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white " target="_blank" href="https://hob.fra1.cdn.digitaloceanspaces.com/UPUTSTVO%20ZA%20KORIS%CC%8CC%CC%81ENJE%20APLIKACIJE.pdf">{{loc.tutorial_and_faq}}</a>-->
<!--                      </MenuItem>-->

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/tutorial-and-faq')" class="block py-2 px-4 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.tutorial_and_faq }}</div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/download-zone')" class="block py-2 px-4 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.my_download_zone }}</div>
                      </MenuItem>

<!--                      <MenuItem v-slot="{ active }" class="cursor-pointer">-->
<!--                        <a class="block px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white " target="_blank" href="/team-building-application-template(2024).xlsx">Team building template</a>-->
<!--                      </MenuItem>-->

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="$router.push('/contact')" class="block pb-2 px-4 pt-2 text-gray-700 hover:bg-base-600 hover:text-white ">{{ loc.contact }}</div>
                      </MenuItem>

                      <MenuItem v-if="user.is_company_admin" v-slot="{ active }" class="hidden lg:flex cursor-pointer">
                        <div @click="switchToAdmin" class="border-t border-gray-200 px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white flex items-center justify-between">
                          <p>{{ loc.switch_to_admin }}</p>
                          <Switch v-model="isAdmin" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-base-600 focus:ring-offset-2">
                            <span class="sr-only">Use setting</span>
                            <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md" />
                            <span aria-hidden="true" :class="[isAdmin ? 'bg-base-600' : 'bg-gray-300', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full duration-200 ease-in-out']" />
                            <span aria-hidden="true" :class="[isAdmin ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
                          </Switch>
                        </div>
                      </MenuItem>

                      <MenuItem v-slot="{ active }" class="cursor-pointer">
                        <div @click="logout" class="border-t border-gray-200 flex items-center px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white rounded-b-md">
                          {{ loc.logout }}
                          <font-awesome-icon  icon="fa-arrow-right-from-bracket" class="ml-2 w-5 h-5" />
                        </div>
                      </MenuItem>

                    </MenuItems>
                  </div>

                </Menu>
              </div>
            </div>
          </div>


          <!--          Druga linija-->
          <div class="hidden lg:flex w-10/12 mx-auto justify-around items-center shadow-xl transform -translate-y-5 lg:-translate-y-8 rounded-lg">
            <button @click="goToFlexibleBenefits" type="button" :class="`rounded-l-lg w-1/3 h-11 text-sm leading-4 shadow-lg bg-base-600 text-shadow-white hover:bg-base-500 font-medium border-white transition ease-in-out duration-200 text-white ${this.$route.path.includes('/flexible-benefits') ? 'box-border border-b-4 border-white' : ''}`">{{ loc.flexible_benefits }}</button>
            <button @click="goToFixedBenefits" type="button" :class="`w-1/3 h-11 text-sm leading-4 font-medium bg-base-600 text-shadow-white hover:bg-base-500 shadow-lg transition ease-in-out duration-200 text-white ${this.$route.path.includes('/fixed-benefits') ? 'box-border border-b-4 border-white' : ''}`">{{ loc.core_benefits }}</button>
            <button @click="goToSpecialOffers" type="button" :class="`rounded-r-lg w-1/3 h-11 text-sm leading-4 bg-base-600 text-shadow-white hover:bg-base-500 font-medium shadow-lg transition ease-in-out duration-200 text-white ${this.$route.path.includes('/special-offers') ? 'box-border border-b-4 border-white' : ''}`">{{ loc.special_offers }}</button>
<!--            <button @click="set_benefits_type_check('team_building')" type="button" :class="`inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm hover:text-white ${benefits_type == 'team_building' ? 'bg-base-600 text-white' : 'bg-base-transparent_500 text-base-600'} hover:bg-base-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500`">{{ loc.team_building }}</button>-->
          </div>

          <ul class="lg:hidden w-10/12 h-8 bg-base-600 mx-auto flex justify-around items-center shadow-xl transform -translate-y-6 lg:-translate-y-8 rounded-lg">
            <li @click="goToFlexibleBenefits" :class="`w-1/3 h-8 pl-1 leading-4 flex items-center justify-center text-white space-x-1 rounded-l-lg ${this.$route.path.includes('/flexible-benefits') ? 'box-border border-b-2 border-white' : ''}`">
              <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
              <span class="font-medium text-sm">{{ loc.flexible_benefits_short }}</span>
            </li>
            <li @click="goToFixedBenefits" :class="`w-1/3 h-8 leading-4 flex items-center justify-center text-white space-x-1 ${this.$route.path.includes('/fixed-benefits') ? 'box-border border-b-2 border-white' : ''}`">
              <font-awesome-icon icon="fa-solid fa-lock" />
              <span class="font-medium text-sm">{{ loc.core_benefits_short }}</span>
            </li>
            <li @click="goToSpecialOffers" :class="`w-1/3 h-8 pr-1 leading-4 text-white space-x-1 flex items-center justify-center rounded-r-lg ${this.$route.path.includes('/special-offers') ? 'box-border border-b-2 border-white' : ''}`">
              <div class="w-5 h-5 rounded-full border-2 border-white flex items-center justify-center transform scale-90">
                <font-awesome-icon icon="fa-solid fa-percentage" class="text-sm" />
              </div>
              <span class="font-medium text-sm">{{ loc.special_offers_short }}</span>
            </li>
          </ul>

        </div>
      </div>

      <main class="flex-1">
         <div class="pt-6">
           <div :class="`max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:pl-28 ${isShowSidebar && !isSidebarDisabled ?  `pl-16 md:pl-28 ml-2.5 xl:mx-auto` : 'px-4'} `">
            <!-- Replace with your content -->
             <div class="lg:ml-2 xl:ml-0">
               <slot/>
             </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
    <div class="lg:hidden w-full fixed bottom-0 left-0 flex items-center justify-center z-50 h-0">
      <div @click="open_card" class="h-14 w-14 rounded-full bg-black text-orange-500 mb-14 flex items-center justify-center">
        <font-awesome-icon icon="fa-solid fa-credit-card-alt" class="text-2xl text-white" />
      </div>

    </div>
  </div>
  <transition
      name="custom-classes-transition"
      enter-active-class="animated tada"
      leave-active-class="animated bounceOutRight"
  >
    <loader v-if="$store.getters['ui/is_loading']"/>
  </transition>

  <modal
      :open="notsModal"
      :hide_submit="true"
      :cancel-text="loc.cancel"
      :title="`${loc.notifications}`"
      @close="notsModal = false; preview_notifications();"
      class="transform translate-x-0 md:translate-x-0"
      size="small"
  >
    <notifications-modal
        @onNotificationClick="openSingleNotificationModal($event);"
        :notifications="$store.state.auth.user.notifications"
    />
  </modal>

  <modal
      :open="singleNotificationModal"
      :hide_submit="true"
      :cancel-text="loc.cancel"
      :title="`${loc.notifications}`"
      @close="singleNotificationModal = false; preview_notifications();"
      class="transform translate-x-0 md:translate-x-0"
      size="small"
  >
    <notifications-modal
        :isSingleNotification="true"
        :notifications="[notification]"
    />
  </modal>

  <hob-card-modal :is-open="card" size="small" @onClose="card = false">
    <div class="hidden md:flex items-center justify-center h-full md:w-500 mx-auto ">
      <div style="background-image: url('/hob-card.png'); height: 380px; width: 500px; margin: auto; background-size:contain; background-repeat: no-repeat;"
           class="flex flex-col justify-start pt-40 px-10 text-2xl font-bold text-white z-50"
      >
        <p>{{$store.state.auth.user.company.name}}</p>
        <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
        <p>HoB User ID: {{$store.state.auth.user.id}}</p>
      </div>
    </div>
    <div class="md:hidden flex items-center justify-center h-72 bg-transparent">
      <img src="../assets/images/hob-card.png" class="w-screen transform scale-110" alt="HoB Card">
      <div class="absolute text-white font-bold leading-5 bg-cover transform translate-y-8 -translate-x-12 pl-2">
        <p>{{$store.state.auth.user.company.name}}</p>
        <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
        <p>HoB User ID: {{$store.state.auth.user.id}}</p>
      </div>
    </div>
  </hob-card-modal>

<!--  <custom-modal :open="card" submit-text="Save" @close="card = false" :has-cancel-button="false" size="medium" class="z-50 mt-40 md:mt-0 self-center">-->
<!--    <div class="hidden md:flex items-center justify-center h-full md:w-500 mx-auto ">-->
<!--      <div style="background-image: url('/kartica.png'); height: 380px; width: 500px; margin: auto; background-size:contain; background-repeat: no-repeat;"-->
<!--           class="flex flex-col justify-start pt-40 px-10 text-2xl font-bold text-white z-50"-->
<!--      >-->
<!--        <p>{{$store.state.auth.user.company.name}}</p>-->
<!--        <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>-->
<!--        <p>HoB User ID: {{$store.state.auth.user.id}}</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="md:hidden flex items-center justify-center h-72 bg-transparent">-->
<!--      <img src="../assets/images/hob-card.png" class="w-screen transform scale-110" alt="HoB Card">-->
<!--      <div class="absolute text-white font-bold leading-5 bg-cover transform translate-y-8 -translate-x-12 pl-2">-->
<!--        <p>{{$store.state.auth.user.company.name}}</p>-->
<!--        <p>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>-->
<!--        <p>HoB User ID: {{$store.state.auth.user.id}}</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </custom-modal>-->
</template>

<script>
import { ref } from 'vue'
import { Switch } from '@headlessui/vue'
import newLogo from "@/components/ui/NewLogo";
import sidebar from "@/components/ui/sidebar";
import loader from "@/components/ui/loader";
import loginLogo from "@/components/ui/LoginLogo";
import modal from "@/components/ui/modal";
import customModal from "@/components/ui/customModal";
import {LogoutIcon} from "@heroicons/vue/solid"
import HobCardModal from "@/components/ui/HobCardModal.vue";

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
    UserIcon,
    ChevronDownIcon,
    HeartIcon as HeartOutline
} from '@heroicons/vue/outline'
import { SearchIcon, HeartIcon, ShoppingCartIcon, BellIcon } from '@heroicons/vue/solid'
import {mapActions, mapMutations, mapState} from "vuex";
import SuccessNotification from "@/components/ui/SuccessNotification";
import ErrorNotification from "@/components/ui/ErrorNotification";
import Cart from "@/views/Cart";
import notificationsModal from "@/components/notificationsModal.vue";

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  { name: 'Team', href: '#', icon: UsersIcon, current: false },
  { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  { name: 'Documents', href: '#', icon: InboxIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

const solutions = [
  { name: 'Blog', description: 'Learn about tips, product updates and company culture.', href: '#' },
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums of contact support.',
    href: '#',
  },
  { name: 'Guides', description: 'Learn how to maximize our platform to get the most out of it.', href: '#' },
  { name: 'Events', description: 'Check out webinars with experts and learn about our annual conference.', href: '#' },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#' },
]

export default {
  components: {
    notificationsModal,
    Cart,
    ErrorNotification,
    SuccessNotification,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    HobCardModal,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    newLogo,
    sidebar,
    loader,
    HeartIcon,
    ShoppingCartIcon,
    loginLogo,
    UserIcon,
    ChevronDownIcon,
    HeartOutline,
    modal,
    customModal,
    LogoutIcon
  },
  setup() {
    const sidebarOpen = ref(false)
    const open = ref(false)

    return {
      navigation,
      userNavigation,
      sidebarOpen,
      solutions,
      open
    }
  },
  data() {
    return {
      card: false,
      loading: false,
      isShowSidebar: true,
      notsModal: false,
      singleNotificationModal: false,
      isAdmin: false,
      notification: null,
      disabledSidebarRoutes: ['/profile', '/suggestion', '/transactions', '/contact', '/reimbursement', '/tutorial-and-faq', '/download-zone'],
    }
  },
  computed: {
    ...mapState('benefits', {
      benefits_type: 'benefits_type',
      home_page: 'home_page',
    }),
    isSidebarDisabled() {
      let screen_w = window.screen.width
      if (screen_w < 769) {
        if (this.home_page) return true;
        if (this.benefits_type === 'fixed') return true;
        if (this.disabledSidebarRoutes.includes(this.$route.path)) return true;
        else false;
      }
      return false;
    },
    total_budget() {
      return this.$store.state.auth.user.base_balance + this.$store.state.auth.user.category_budgets.reduce((partialSum, a) => partialSum + Number(a.amount), 0)
    },
    // totalDiscount() {
    //   return this.$store.state.auth.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100 * a.count, 0)
    // },
    total_budget_current() {
      return Number(this.$store.state.auth.user.balance.split(" ")) + this.$store.state.auth.user.category_budgets.reduce((partialSum, a) => partialSum + Number(a.amount) - Number(a.spent), 0)
    },
    user() {
      return this.$store.state.auth.user;
    },
    totalAmountWithDiscount() {
      const priceWithDiscount = this.user.cart.reduce((total, benefit) => {
        const price = parseFloat(benefit.benefit.price);
        const discount = benefit.benefit.discount;
        const quantity = benefit.count;

        const amountWithDiscount = price - (price * (discount / 100));
        const totalAmount = amountWithDiscount * quantity;

        return total + totalAmount;
      }, 0);
      return priceWithDiscount;
    },
    totalDiscount() {
      // return this.user.cart.reduce((partialSum, a) => Number(a.benefit.price), 0)
      const totalDiscount = this.user.cart.reduce((total, benefit) => {
        const price = parseFloat(benefit.benefit.price);
        const discount = benefit.benefit.discount;
        const quiantity = benefit.count;

        const priceWithoutDiscount = price * quiantity;
        const priceWithDiscount = priceWithoutDiscount - (priceWithoutDiscount * (discount / 100));

        return total + (priceWithoutDiscount - priceWithDiscount);
      }, 0);
      return totalDiscount;
    },
    totalAmount() {
      const total = this.user.cart.reduce((total, benefit) => {
        const price = parseFloat(benefit.benefit.price);
        const quantity = benefit.count;
        return total + (price * quantity);
      }, 0);
      return total;
    },
  },
  methods: {
    ...mapActions('benefits', ['set_benefits_type']),
    ...mapActions('auth', {
      toggle_cart: 'toggleCart',
      fetchUser: 'fetchUser'
    }),
    ...mapActions('filters', ['select_category', 'select_sort', 'set_ordering']),
     ...mapMutations('auth', {
       resetCartItem: 'resetCartItem',
       logOut: 'logOut',
    }),
    ...mapMutations('auth', ['setLanguageHelper']),
    ...mapMutations('benefits', ['set_home_page']),
    ...mapMutations('filters', ['reset_filters']),
    ...mapMutations('ui', {
      switch_view: "switch_benefit_preview_type"
    }),
    switchToAdmin() {
      window.location.href = process.env.VUE_APP_ADMIN_DOMEN;
    },
    async goToBenefitsType(type, cat) {
      if (this.$route.path === '/') {
        this.set_benefits_type_check(type, cat);
      } else {
        await this.$router.push('/');
        this.set_benefits_type_check(type, cat);
      }
    },
    open_card() {
      this.card = true
    },
    back_to_home() {
      // this.set_benefits_type_check('flexible', null);
      this.set_home_page(true);
      this.switch_view('grid');
    },
    async preview_notifications() {
      await this.axios.post('v2/preview-notifications/')
      await this.fetchUser()
    },
    openSingleNotificationModal(payload) {
      this.notsModal = false;
      this.notification = payload;
      this.singleNotificationModal = true;
    },
    async set_benefits_type_check(value, cat) {
      this.reset_filters();
      await this.set_benefits_type(value).then(() => {
        if (value === 'special') {
          this.select_sort('bought');
          this.set_ordering('-');
        }
        this.select_category(cat);
      })
    },
    async changeCartItemCount(count, id) {
      this.loading = true;
      try {
        let {data} = await this.axios.patch(`v2/cart-items/${id}/`, {count: count})
        await this.fetchUser()
        this.resetCartItem(data)
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async change_language(lang) {
      this.setLanguageHelper(lang);
      await this.axios.patch('v2/me/',{'language': this.$store.state.auth.user.language == "SR" ? "EN" : "SR"})
      await this.fetchUser()
    },
    logout() {
      this.logOut()
      this.$router.push('/login')
      this.set_home_page(true)
    },
    setLogo(val) {
      this.open = val;
    },
    goToFlexibleBenefits() {
      // this.set_benefits_type_check('flexible', 1);
      this.$router.push(`/flexible-benefits/${1}`);
    },
    goToSpecialOffers() {
      // this.set_benefits_type_check('special', 1);
      this.$router.push(`/special-offers/${1}`);
    },
    goToFixedBenefits() {
      this.set_benefits_type_check('fixed', null);
      this.$router.push(`/fixed-benefits`);
    }
  },
  mounted() {
    const _this = this

    setInterval(() => (
        _this.fetchUser()
    ), 7000)
    // let content_width = document.getElementById('content-space').offsetWidth
    // document.getElementById('header-items-space').style.width = content_width + 'px'


  }
}
</script>

<style>
/*.container {*/
/*  width: 250px !important;*/
/*  text-align: center;*/
/*}*/

.progress2 {
  padding: 2px;
  border-radius: 5px;
  background: theme('colors.base.transparent_600');
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 7px;
  /*border-radius: 30px;*/
  /*background-image:*/
  /*    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));*/
  /*transition: 0.4s linear;*/
  /*transition-property: width, background-color;*/
}

.progress-moved .progress-bar2 {
  width: 85%;
  /*background-color: theme('colors.base.500');*/
  animation: progressAnimation 6s;
}

.text-shadow-white:hover {
  text-shadow: -1px 0px 50px rgba(255,255,255,0.95);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 256px !important;
  background-color: white !important;
  color: black !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
  /*text-shadow: 0 0 7px white, 0 0 7px white, 0 0 7px white;*/
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#046c9c, #088fcc);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#046c9c, #034e70);
  border-radius: 20px;
}

.color {
  background-color: rgb(8, 109, 157);
}

.aspect-3-2 {
  aspect-ratio: 3 / 2;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>