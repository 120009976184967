<template>
  <section class="py-4 text-gray-600 text-center flex items-center justify-center">
    <div>
      <div class="flex items-center justify-center  rounded-full border mx-auto h-10 w-10">
<!--        <FontAwesomeIcon icon={faTrashCan} class="text-xl text-white" />-->
        <font-awesome-icon icon="fa-solid fa-bell" :class="`w-6 h-6 ${iconColor ? iconColor : 'text-base-600'}`" />
      </div>
      <p class="font-medium mt-2">{{ title }}</p>
    </div>
  </section>
  <section class="text-center text-gray-600 border-t border-b px-4 lg:px-8 py-8">
    <p>{{ description }}</p>
  </section>
  <section class="flex items-center justify-center gap-x-6 px-4 py-6">
    <button @click="$emit('onCancel')" class="border-2 border-base-600 w-1/2 h-10 hover:bg-base-600 text-base-600 hover:text-white font-medium rounded-lg animation duration-200 ease-in-out">
      {{ loc.cancel }}
    </button>
    <button :disabled="loading" @click="$emit('onConfirm')" :class="`w-1/2 h-10 font-medium rounded-lg animation duration-200 ease-in-out ${confirmButtonClasses ? confirmButtonClasses : 'bg-green-600 hover:bg-green-100 text-white hover:text-green-700 border-2 border-green-600 '}`">
      {{ confirmBtnText }}
    </button>
  </section>
</template>

<script>
export default {
  name: "ConfirmDenyModal",
  props: ['title', 'description', 'confirmBtnText', 'confirmButtonClasses', 'iconColor', 'loading'],
}
</script>


<style scoped>

</style>