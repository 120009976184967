import http from '@/config/http';
import { formatDate } from '@/libs/date-time';

class SubscriptionApi {
  getSubscriptions = () => {
    return http.get(`/v2/subscriptions/`)
  };

  subscribeBenefit = (payload) => {
    return http.post('/v2/subscriptions/', payload);
  };

  unsubscribeBenefit = (subscriptionId) => {
    return http.patch(`/v2/subscriptions/${subscriptionId}/`, { subscription_active: false, canceled_at: formatDate(new Date(), 'YYYY-MM-DD') });
  }
}

export default SubscriptionApi;