import { createStore } from 'vuex'
import auth from './modules/auth'
import courses from "./modules/courses";
import cart from "./modules/cart";
import filters from "./modules/filters"
import benefits from "./modules/benefits"
import ui from "./modules/ui"
import subscriptionStore from '@/modules/subscription/services/subscription.store';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    courses,
    cart,
    filters,
    benefits,
    ui,
    subscriptionStore
  }
})
