function monetary(value) {
    if (value === "") {
        return ""
    }
    let currency = 'RSD'
    const formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
    return formated.split(currency)[1] + ' ' + currency;
}

export default {
    "SR": {
        invoices: "Prilog",
        invoice: "Račun",
        language: "Jezik",
        flexible_benefits: "Fleksibilni benefiti",
        flexible_benefits_short: "Fleksibilni",
        logout: "Odjavite se",
        fixed_benefits: "Fiksni benefiti",
        special_offers: "Specijalne ponude",
        special_offers_short: "Specijalni",
        no_benefits: "Nema benefita",
        benefit_cockpit: "Paleta benefita",
        flexible_desc: "U ovoj sekciji možete pronaći sve Benefite koji se mogu ostvariti uz pomoć budžeta koji je obezbedio Vaš poslodavac. Molimo Vas da odaberete Benefit koji želite.",
        fix_desc: "U ovoj sekciji možete pronaći sve informacije o fiksnim (osnovnim) benefitima koje obezbeđuje Vaš poslodavac. Ovi benefiti ne mogu biti izabrani, otkazani ili promenjeni jer su oni unapred predefinisani za Vas.",
        special_desc: "U ovoj sekciji možete pronaći sve specijalne ponude i popuste na standardne cene koje za Vas obezbeđuju naši Partneri. Specijalni popusti, uz HoB membership karticu, važe i za kupovinu iz sopstvenih novčanih sredstava, nevezano za budžet koji je poslodavac dodelio na platformi za Fleksibilne benefite.",
        cart: "Korpa",
        prev_cart: "Pregledajte korpu",
        total: "Ukupno",
        empty_cart: "Korpa je prazna",
        proceed: "Kupi",
        number_of_benefits: "Broj benefita",
        by_error: "Nemate dovoljno budžeta!",
        profile: "Pogledaj profil",
        profile_text: "Ovde mozete da pregledate i izmenite informacije o profilu",
        profile_img: "Profilna slika",
        current_balance: "Trenutno stanje",
        benefits_chosen: "Izabrano benefita",
        benefits_bought: "Kupljeno benefita",
        total_spent: "Ukupno potrošeno",
        yearly_total_budget: "Ukupan benefit budžet",
        flex_benefit_budget: "Flex benefit budžet",
        benefit_budget: "Benefit budžet",
        spent: "Potrošeno",
        left: "Preostalo",
        company: "Kompanija",
        first_name: "Ime",
        last_name: "Prezime",
        company_mail: "Kompanijski e-mail",
        phone_number: "Broj telefona",
        change_password: "Promeniti lozinku",
        password: "Lozinka",
        current_password: "Trenutna lozinka",
        new_password: "Nova lozinka",
        repeat_password: "Potvrdite novu lozinku",
        error: "Greška",
        password_changed: "Lozinka je promenjena",
        transactions: "Moje kupovine",
        view_transactions: "Pogledaj kupovine",
        no_transactions: " Nema transakcija za izabrane filtere.",
        transactions_desc: "Ovde se nalazi pregled svih tvojih kupovina kod partnera i možeš da ih filtriraš po vremenskom periodu, partneru ili statusu kupovine.",
        my_transactions: "Moje kupovine",
        my_subscriptions: "Moje pretplate",
        subscriptions_desc: "Ovde se nalazi pregled svih Vaših aktivnih pretplata. Pretplata se automatski obnavlja svakog narednog 5-og dana u mesecu sve dok ne uradite Odjavu ili nemate dovoljno budžeta za kupovinu.",

        confirm_subscribe_question: "Da li ste sigurni da želite da se pretplatite na ovaj benefit? Pretplata se obnavlja svakog 5-og dana u mesecu ukoliko je dostupan budžet za kupovinu.",
        confirm_unsubscribe_question: "Da li ste sigurni da želite da odjavite pretplatu za ovaj benefit? Odjava je aktivna od narednog meseca.",
        subscribe: "Pretplati se",
        unsubscribe: "Odjavi pretplatu",
        insufficient_budget: "Nemate dovoljno budžeta za mesečnu pretplatu na ovaj benefit",
        successfully_subscribed: "Uspešno ste se pretplatili na benefit!",
        successfully_unsubscribed: "Uspešno ste odjavili pretplatu benefita!",
        no_subscriptions: "Trenutno nema aktivnih pretplata.",

        req_sent: "Slanje zahteva",
        req_process: "Obrada zahteva",
        req_completed: "Zahtev obrađen",
        success_by: "Uspešno ste izvršili kupovinu",
        complete: "Završi",
        not_completed: "U procesu",
        completed: "Završeno",
        subcategories: "Potkategorije",
        name: "Naziv benefita",
        required: "Ovo polje je obavezno",
        not_email: "Neispravan email",
        edit_provider: "Uredi provajdera",
        are_you_sure: "Da li ste  sigurni?",
        prov_will_del: "Provajder će biti obrisan",
        comp_will_del: "Kompanija će biti obrisana",
        user_will_del: "Korisnik će biti obrisan",
        admin_will_del: "Administrator će biti obrisan",
        benefit_will_del: "Benefit će biti obrisan",
        delete: "Obrisati",
        cancel: "Prekini",
        create_benefit: "Kreirajte benefit",
        create_provider: "Kreirajte provajdera",
        discount: "Popust",
        category: "Kategorija",
        budget_for_category: "Budžet za kategoriju",
        subcategory: "Potkategorija",
        valid_from: "Važi od",
        valid_to: "Važi do",
        description: "Opis",
        short_description: "Kratak opis",
        company_price: "Procenat poreza i doprinosa",
        instructions: "Instrukcije",
        limitations: "Ograničenja",
        note: "Informacija o poreskom tretmanu",
        edit_benefit: "Izmeni benefit",
        details: "Detalji",
        promo: "Prilog",
        provider: "Provajder",
        benefit_provider: "Benefit provajder",
        add_cart: "Dodaj u korpu",
        remove_cart: "Ukloni iz korpe",
        most_popular: "Najpopularnije",
        recently_added: "Najnovije",
        favorites: "Omiljeno",
        no_favorites: "Niste dodali nijedan omiljeni benefit na listu",
        sort_by: "Sortiraj po",
        bought: "Popularnosti", added: "Vreme",
        price: "Cena",
        total_price: "Ukupna cena",
        create_company: "Kreirajte kompaniju",
        comany_id: "Kompanijski ID",
        create_user: "Kreiraj korisnika",
        base_balance: "Ukupan budžet",
        id_company: "Kompanijski ID",
        user_created: "Korisnik je kreiran",
        edit_user: "Izmeni korisnika",
        create_fixed_benefits: "Kreiraj fiksne benefite",
        not_num: "Unos nije broj",
        active_status: "Aktivan status",
        view_all: "Prikaži sve",
        nfc: "NFC",
        date: "Datum",
        id_user: "Korisnički ID",
        vat: "*PDV uključen u cenu",
        checked_cart: "Razumem da će moj poslodavac platiti naknadno porez i doprinose na prikazanu cenu benefita prema državnoj legislativi",
        edit_company: "Uredi kompaniju",
        contact: "Kontakt",
        contact_us: "Kontaktirajte nas",
        address: "Adresa",
        email: "E-mejl",
        support: "Veb-sajt",
        contact_support: "Kontaktiraj podršku",
        support_msg: "Čet podrška uživo, je dostupna na web platformi",
        about: "O Nama",
        providers: "Provajderi",
        reset_providers: "Poništi provajdere",
        proceed_to_checkout: " Na kasu",
        confirm_purchase: "Potvrdite kupovinu",
        back_to_cart: "Nazad u korpu",
        transaction_successful: "Uspešna kupovina",
        money_left: (money) => { return `Ostalo Vam je ${monetary(money)} na vašem nalogu.` },
        benefit_suggestion: "Predložite benefit",
        benefit_suggestion_text: "Napišite i pošaljite vašu sugestiju",
        write_suggestion: "Napišite Vašu preporuku",
        suggestion_desc: "Ovde možete napisati Vašu preporuku novog benefita ili partnerske firme. Molimo vas da u formi ispod pored naziva dostavite i link ka web sajtu ukoliko postoji.",
        confirm: "Potvrdi",
        success_sending_suggestion: "Uspešno ste poslali predlog",
        close: "Zatvori",
        companies: "Kompanije",
        benefit_providers: "Benefit provajderi",
        create_admin: "Kreirajte administratora",
        tutorial: "Tutorijal",
        tutorial_and_faq: "Tutorijal & FAQ",
        amount: "Količina",
        user_edited: "Korisnik je izmenjen",
        sort: "Sortiraj po",
        additional_payrole: "Dodatni porez na teret poslodavca: ",
        additional_tax: "*Ovo je samo procenjena vrednost poreza i doprinosa koji će dodatno biti obračunati i plaćeni od strane Vašeg poslodavca na regularnu cenu benefita",
        search: "Pretraži",
        image: "Slika",
        actions: "Akcije",
        balance: "Ukupan budžet",
        position: "Pozicija",
        country: "Država",
        all_questions: "Sva pitanja",
        team_building: "Tim bilding",
        load: "Učitaj",
        change_str: "Izmeni",
        add_photo: "Postaviti sliku",
        order_summary: "Pregled porudžbine",
        fully_taxable: "U potpunosti oporeziv benefit",
        location: "Lokacija",
        category_budgets: "Dodatni budžet za specifične kategorije",
        benefits: "Benefiti",
        base_salary: "Osnovna plata",
        guaranteed_allowances: "Garantovani dodaci",
        annual_bonus: "Godišnji bonus",
        status: "Status",
        total_rewards: "Ukupno nagrađivanje",
        rewards_text: "Prikazani iznos predstavlja teoretski, ugovoreni, targetirani iznos ukupne godišnje zarade bez dodatnih umanjenja koja mogu nastati usled nekih nastalih okolnosti.",
        account: "Nalog",
        help: "Podrška",
        notifications: "Obaveštenja",
        no_notifications: "Nemate poslatih obaveštenja",
        flex_budgets: "Flex benefit budžet",
        no_favs: "Niste dodali nijedan omiljeni benefit na listu",
        cart_empty: "Korpa je prazna",
        my_card: "Moja HoB kartica",
        yearly: "godišnje",
        core_benefits: "Osnovni benefiti",
        core_benefits_short: "Osnovni",
        start_shopping: "Kreni u kupovinu",
        learning: "Lični razvoj",
        discounts: "Popusti",
        id_or_provider_name: "Unesi naziv provajdera ili ID broj",
        quantity: "Količina",
        apply_filters: "Primeni filtere",
        date_from: "Datum od",
        date_to: "Datum do",
        vat_is_included: "(PDV je uračunat u cenu)",
        vat_number: "PIB",
        registration_number: "MB",
        additional_payroll_tax: "Dodatni porez i doprinosi",
        your_cart: "Tvoja korpa",
        benefits_you_want: "Ovde se nalaze benefiti koje želiš da kupiš",
        orders: "Porudžbine",
        remove_benefit: "Ukloni benefit",
        processing: "Učitavanje",
        promotional_saving: "Akcijska ušteda",
        no_attachments: "Nema dostupnih priloga.",
        recommended: "Preporučeno",
        because_people_matter: "Jer ljudi su bitni",
        ways_of_buying: "Načini kupovine",
        enjoy_the_benefits: "Uživaj u benefitima dok si u pokretu.",
        benefits_in_movement: "Uz HoB mobilnu aplikaciju lako ostvaruješ kupovinu benefita i dok si u pokretu - uvek i svuda.",
        find_out_more: "Saznaj više",
        follow_us: "Pratite nas na LinkedIn-u",
        you_have_a_question: "Imate pitanje?",
        search_by_benefit_title: "Pretraži po nazivu benefita",
        search_by_provider_name: "Pretraži po nazivu provajdera",
        loading: "Učitavanje...",
        select: "Izaberi",
        err_min_length: "Lozinka mora imati najmanje 8 znakova.",
        err_has_letter: "Lozinka mora sadržati najmanje jedno slovo (a-ž ili A-Ž).",
        err_has_special_char: "Lozinka mora sadržati najmanje jedan poseban znak (!@#$%^&*).",
        err_passwords_do_not_match: "Lozinke se ne podudaraju.",
        working_hours: "Radno vreme: Pon - Pet, 9 - 19h",
        cancelled: "Stornirano",
        belgrade_serbia: "Beograd, Srbija",
        security_guidelines: "Sigurnosne smernice",
        at_least_eight_characters: "Najmanje 8 znakova",
        at_least_one_letter: "Najmanje jedno slovo (a-ž ili A-Ž)",
        at_least_one_different_character: "Najmanje jedan drugačiji znak (brojevi, posebni znakovi, ...)",
        no_spaces: "Bez razmaka na početku i na kraju",
        my_profile: "Moji podaci",
        total_benefit_budget_tooltip: "Prikazan iznos predstavlja sumu svih benefit budžeta (FLEX + bužeti za specifične kategorije, npr. Edukacija, Zdravlje, Kutak za decu i sl.).",
        transaction_is_successful: "Kupovina je uspešno obavljena!",
        send: "Pošalji",
        time: "Vreme",
        upload_invoice: "Pošalji račun",
        my_reimbursements: "Moje refundacije",
        comment: "Komentar",
        want_to_make: "Želim da izvršim",
        user: "Korisnik",
        reimbursement_1: "Refundaciju",
        reimbursement: "Refundacija",
        transaction_1: "Kupovinu",
        transaction: "Kupovina",
        choose_benefit_category: "Izaberi kategoriju benefita",
        enter_amount_for_reimbursement: "Unesi iznos refundacije",
        upload_document: "Učitaj dokument",
        upload_file: "Učitaj dokument ili prevuci",
        new_request: "Novi zahtev",
        reimbursement_amount_error: "Iznos koji ste uneli ne sme biti veći od dostupnog budžeta!",
        no_requests_found: "Nema pronađenih zahteva!",
        no_comment_found: "Nema pronađenih komentara!",
        successfully_sent_request: "Uspešno ste poslali zahtev!",
        type: "Tip",
        accepted: "Prihvaćeno",
        rejected: "Odbijeno",
        pending: "Na čekanju",
        in_review: "U obradi",
        reimbursements_desc: "Ovde se nalazi pregled svih tvojih zahteva za kupovinu kod partnera po predračunu ili podnetih zahteva za refundaciju",
        my_requests: "Moji zahtevi",
        switch_to_admin: "Admin nalog",
        there_are_no_notifications: "Nema primljenih obaveštenja!",
        next: "Sledeće",
        previous: "Prethodno",
        something_went_wrong: "Nešto nije u redu!",
        refund_amount: "Iznos refundacije",
        download_here: "Preuzmi ovde",
        my_download_zone: "Moja download zona",
        files_and_templates: "Fajlovi i šabloni",
        presentations: "Prezentacije",
        frequently_asked_questions: "Najčešće postavljena pitanja ",
        transaction_is_successful_desc: "Kupovina je uspešno obavljena, status realizacije možete pratiti u sekciji Moje kupovine.",
        home_page: "Početna stranica",
        page_not_found: "Stranica nije pronađena",
        page_not_found_desc: "Žao nam je, nismo mogli da pronađemo stranicu koju tražite.",
        faq_one_question: "Šta su to benefiti za zaposlene?",
        up_to_2mb: "do 2MB",
        faq_one_answer: "Benefiti su sve dodatne stvari koje poslodavac plaća u ime svojih zaposlenih\n" +
          "pored plate. Na primer, pogodnosti mogu uključivati popuste za članarinu\n" +
          "u teretani ili tretmane masaže, kompanijski automobil, privatnu penziju i\n" +
          "zdravstveno osiguranje ili pak poklon za decu. Fleksibilni benefiti su model\n" +
          "koji vam omogućava da samostalno birate svoje pakete benefita, u\n" +
          "definisanom budžetu koji dodeljuje poslodavac, kako biste zadovoljili\n" +
          "personalizovane potrebe i zahteve.",
        enter_decimal_values_with_dots: "Unesi decimale znakom “.” ",
    },
    "EN": {
        language: "Language",
        flexible_benefits: "Flexible Benefits",
        flexible_benefits_short: "Flexible",
        logout: "Log Out",
        current_balance: "Current Balance",
        fixed_benefits: "Fixed Benefits",
        special_offers: "Special Offers",
        special_offers_short: "Special",
        no_benefits: "There are no flexible-benefits",
        benefit_cockpit: "Benefit cockpit",
        benefits_desc: "In the left menu bar section you can find all the flexible-benefits that can be accessed with the budget provided by your employer. Please choose the benefit according to your wishes.",
        flexible_desc: "In this section you can find all the flexible-benefits that can be accessed with the budget provided by your employer. Please choose the benefit according to your wishes.",
        fix_desc: "In this section you can find all the information about the fixed flexible-benefits provided by your employer. These flexible-benefits can not be chosen, cancelled or exchanged as they are provided by default for you.",
        special_desc: "In this section you can find all special offers and discounts on standard prices provided for you by our Partners. Special discounts, with the HoB membership card, also apply to purchases from your own funds, unrelated to your budget allocated by the employer on the platform for Flexible benefits section.",
        cart: "Cart",
        prev_cart: "View Full Cart",
        total: "Total",
        empty_cart: "Cart is empty",
        proceed: "Proceed to checkout",
        number_of_benefits: "Number of flexible-benefits",
        by_error: "Not enough available budget!",
        profile: "View Profile",
        profile_text: "here you can see and change profile info",
        profile_img: "Profile image",
        benefits_chosen: "Benefits Chosen",
        benefits_bought: "Benefits Bought",
        total_spent: "Total Spent",
        yearly_total_budget: "Total Benefit Budget",
        flex_benefit_budget: "Flex Benefit Budget",
        spent: "Spent",
        left: "Left",
        company: "Company",
        first_name: "First name",
        last_name: "Last name",
        company_mail: "Company Email",
        phone_number: "Phone number",
        change_password: "Change password",
        password: "Password",
        current_password: "Current password",
        new_password: "New password",
        repeat_password: "Repeat password",
        error: "Error",
        password_changed: "Password changed",
        my_transactions: "My Transactions",
        my_subscriptions: "My Subscriptions",
        subscriptions_desc: "Here is an overview of all your active subscriptions. Subscription renews automatically on the 5th day of every month until you Unsubscribe or do not have enough budget to cover.",

        confirm_subscribe_question: "Please confirm that you want to Subscribe to this benefit. Subscription renews every 5th day in the month if benefit budget is available.",
        confirm_unsubscribe_question: "Please confirm that you want to Unsubscribe from this benefit. Cancelation is active from next month.",
        subscribe: "Subscribe",
        unsubscribe: "Unsubscribe",
        insufficient_budget: "Insufficient budget balance for this monthly subscription",
        successfully_subscribed: "Successfully subscribed!",
        successfully_unsubscribed: "Successfully unsubscribed!",
        no_subscriptions: " There is no active subscription at the moment.",

        view_transactions: "View Transactions",
        no_transactions: " There are no transactions for selected filters.",
        transactions: "My Transactions",
        transactions_desc: "Here is an overview of all your transactions and you can filter them by period of time, partner or status.",
        req_sent: "Request sent",
        req_process: "Request in process",
        req_completed: "Request completed",
        success_by: "You have successfully made a purchase",
        complete: "Complete",
        not_completed: "In Process",
        completed: "Completed",
        subcategories: "Subcategories",
        name: "Benefit Name",
        required: "This field is required",
        not_email: "this is not email",
        edit_provider: "Edit provider",
        are_you_sure: "Are you sure?",
        prov_will_del: "Provider will be deleted",
        comp_will_del: "Company will be deleted",
        benefit_budget: "Benefit Budget",
        user_will_del: "User will be deleted",
        admin_will_del: "Admin will be deletet",
        benefit_will_del: "Benefit will be deleted",
        delete: "Delete",
        cancel: "Cancel",
        create_benefit: "Create benefit",
        create_provider: "Create provider",
        price: "Price",
        total_price: "Total Price",
        discount: "Discount",
        category: "Category",
        budget_for_category: "Budget for category",
        subcategory: "Subcategory",
        valid_from: "Valid from",
        valid_to: "Valid to",
        description: "Description",
        short_description: "Short description",
        company_price: "Percentage of tax and contribution",
        instructions: "Instructions",
        limitations: "Limitations",
        note: "Tax info",
        edit_benefit: "Edit benefit",
        details: "Details",
        promo: "Attachment",
        provider: "Provider",
        benefit_provider: "Benefit Provider",
        add_cart: "Add to cart",
        remove_cart: "Remove from cart",
        most_popular: "Most Popular",
        recently_added: "Recently Added",
        favorites: "Favorites",
        no_favorites: "You haven’t added any benefit to your favorites list",
        sort_by: "Sort by",
        bought: "Popularity", added: "Time",
        create_company: "Create company",
        comany_id: "Company ID",
        create_user: "Create user",
        base_balance: "Total budget",
        id_company: "Company ID",
        user_created: "User is created",
        edit_user: "Edit user",
        create_fixed_benefits: "Create fixed flexible-benefits",
        not_num: "Input is not a number",
        active_status: "Active status",
        view_all: "View All",
        nfc: "NFC",
        date: "Date",
        id_user: "User ID",
        user: "User",
        vat: "*VAT included",
        checked_cart: "I understand that my employer will pay additional payroll taxes and contributions on the benefit price according to fiscal legislation",
        edit_company: "Edit company",
        contact: "Contact",
        contact_us: "Contact Us",
        address: "Address",
        email: "E-mail",
        support: "Website",
        contact_support: "Contact support",
        support_msg: "You can find live chat support on our web platform",
        about: "About",
        providers: "Providers",
        reset_providers: "Reset Providers",
        proceed_to_checkout: "Proceed to checkout",
        confirm_purchase: "Confirm purchase",
        back_to_cart: "Back to cart",
        transaction_successful: "Transaction Successful",
        money_left: (money) => { return `You have left ${monetary(money)} on your account.` },
        benefit_suggestion: "Benefit Suggestion",
        write_suggestion: "Write your suggestion",
        suggestion_desc: "Here you can write your recommendation of a new benefit or partner company. Please provide a link to the website if there is one.",
        confirm: "Confirm",
        success_sending_suggestion: "Suggestion sent",
        close: "Close",
        companies: "Companies",
        benefit_providers: "Benefit providers",
        create_admin: "Create administrator",
        tutorial: "Tutorial",
        tutorial_and_faq: "Tutorial & FAQ",
        user_edited: "User is edited",
        amount: "Amount",
        sort: "Sort",
        additional_payrole: "Additional payroll tax: ",
        additional_tax: "*This is just an estimated tax and contribution amount that will be additionally paid by your employer on the standard flexible-benefits price",
        search: "Search",
        image: "Image",
        actions: "Actions",
        balance: "Base balance",
        position: "Position",
        country: "Country",
        all_questions: "All questions",
        team_building: "Team building",
        load: "Load",
        change_str: "Change",
        add_photo: "Add Photo",
        order_summary: "Order summary",
        fully_taxable: "Fully taxable benefit",
        invoices: "Attachment",
        invoice: "Invoice",
        location: "Location",
        category_budgets: "Additional budget for specific categories",
        benefits: "Benefits",
        base_salary: "Base Salary",
        guaranteed_allowances: "Guaranteed Cash",
        annual_bonus: "Annual Bonus",
        status: "Status",
        total_rewards: "Total Rewards",
        rewards_text: "The amount shown represents the theoretical, contracted, targeted amount of the total annual fee without additional deductions that may arise due to some circumstances.",
        account: "Account",
        help: "Help",
        benefit_suggestion_text: "Please write and send your benefit suggestion",
        notifications: "Notifications",
        no_notifications: "There is no any notification yet",
        flex_budgets: "Flex benefit budget",
        no_favs: "You haven't added any item in your wish list",
        cart_empty: "Your Cart is empty",
        my_card: "My HoB Card",
        yearly: "yearly",
        core_benefits: "Core Benefits",
        core_benefits_short: "Core",
        start_shopping: "Start shopping",
        learning: "Learning",
        discounts: "Discounts",
        id_or_provider_name: "Enter provider name or ID number",
        quantity: "Quantity",
        apply_filters: "Apply filters",
        date_from: "Date from",
        date_to: "Date to",
        vat_is_included: "(VAT is included in the price)",
        vat_number: "VAT",
        registration_number: "ID",
        additional_payroll_tax: "Additional Payroll Tax",
        your_cart: "Your cart",
        benefits_you_want: "Here are the flexible-benefits you want to buy",
        orders: "Orders",
        remove_benefit: "Remove benefit",
        processing: "Processing",
        promotional_saving: "Promotional saving",
        no_attachments: "There are no attachments.",
        recommended: "Recommended",
        because_people_matter: "Because people matter",
        ways_of_buying: " Buying Options",
        enjoy_the_benefits: "Enjoy the Benefits on the go.",
        benefits_in_movement: "HoB App makes it easy for you to enjoy all your Benefits from the palm of your hand. Wherever you are, whenever you want.",
        find_out_more: "Learn more",
        follow_us: "Follow Us On LinkedIn",
        you_have_a_question: "Have a question?",
        search_by_benefit_title: "Search by Benefit Title",
        search_by_provider_name: "Search by Provider Name",
        loading: "Loading...",
        select: "Select",
        err_min_length: "Password must have at least 8 characters.",
        err_has_letter: "The password must contain at least one letter (a-z or AZ).",
        err_has_special_char: "The password must contain at least one special character (!@#$%^&*).",
        err_passwords_do_not_match: "Passwords do not match.",
        working_hours: "Working hours: Mon - Fri, 9 - 19h",
        cancelled: "Canceled",
        belgrade_serbia: "Belgrade, Serbia",
        security_guidelines: "Security Guidelines",
        at_least_eight_characters: "At least 8 characters",
        at_least_one_letter: "At least one letter (a-z or A-Z)",
        at_least_one_different_character: "At least one different character (numbers, special characters, ...)",
        no_spaces: "No spaces at the beginning and end",
        my_profile: "My Profile",
        total_benefit_budget_tooltip: "This displayed amount represents the sum of all budget flexible-benefits (FLEX + budgets for specific categories, e.g. Education, Health, Children's Corner, etc.)",
        transaction_is_successful: "Transaction is successful!",
        send: "Send",
        time: "Time",
        upload_invoice: "Upload Invoice",
        my_reimbursements: "My Reimbursements",
        comment: "Comment",
        want_to_make: "I want to make",
        reimbursement_1: "Reimbursement",
        reimbursement: "Reimbursement",
        transaction_1: "Transaction",
        transaction: "Transaction",
        choose_benefit_category: "Choose Benefit category",
        enter_amount_for_reimbursement: "Enter amount for reimbursement",
        new_request: "New request",
        upload_document: "Upload the document",
        upload_file: "Upload file or drag and drop",
        reimbursement_amount_error: "Amount that you have entered is higher than available budget!",
        no_requests_found: "No request found!",
        no_comment_found: "No comment found!",
        successfully_sent_request: "Successfully sent request!",
        type: "Type",
        accepted: "Accepted",
        rejected: "Denied",
        pending: "Pending",
        in_review: "In review",
        reimbursements_desc: "Here you can find an overview of all your requests for proforma invoice transaction with partners or submitted requests for reimbursement.",
        my_requests: "My Requests",
        switch_to_admin: "Switch to Admin",
        there_are_no_notifications: "There are no notifications!",
        next: "Next",
        previous: "Previous",
        something_went_wrong: "Something went wrong!",
        refund_amount: "Refund amount",
        download_here: "Download here",
        my_download_zone: "My Download Zone",
        files_and_templates: "Files and templates",
        presentations: "Presentations",
        frequently_asked_questions: "Frequently asked questions",
        transaction_is_successful_desc: "The payment has been completed successfully, you can track the status of the realization in section My Transactions. ",
        home_page: "Home Page",
        page_not_found: "Page Not Found",
        page_not_found_desc: "Sorry, we couldn’t find the page you’re looking for.",
        faq_one_question: "What are employee benefits?",
        up_to_2mb: "up to 2MB",
        faq_one_answer: "Employee benefits are all the additional things an employer pays for on behalf of its employees in addition to salary. For example, benefits may include discounts for gym memberships or massage treatments, a personal car, pension and insurances or child gift. Flexible benefits are model that allows you to independently choose your benefit packages, within the benefit budget allocated by the employer, in order to meet personalized needs and requirements.",
        enter_decimal_values_with_dots: "Enter decimal values with “.” ",
    }
}
