import SubscriptionApi from '@/modules/subscription/services/subscription.api';

const api = new SubscriptionApi();

export default {
  namespaced: true,
  state: {
    subscriptions: [],
  },
  mutations: {
    setSubscriptions(state, data) {
      console.log(data, 'data mutation')
      state.subscriptions = data;
    },
  },
  actions: {
    async getSubscriptions(context) {
      try {
        const { data } = await api.getSubscriptions();
        console.log(data, 'DATA STORE GET SUBS')
        context.commit('setSubscriptions', data);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async subscribeBenefit(context, payload) {
      try {
        const apiData = {
          user_id: payload.user_id,
          benefit_id: payload.benefit.id
        }
        const { data } = await api.subscribeBenefit(apiData);
        await context.dispatch('getSubscriptions');
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async unsubscribeBenefit(context, benefitId) {
      try {
        console.log(context.getters.subscriptionsData, 'context.getters.subscriptionsData')
        console.log(benefitId, 'BENEFIT ID')
        const subscription = await context.getters.subscriptionsData.find(sub => sub.benefit.id === benefitId);
        console.log(subscription, 'sub')
        const { data } = await api.unsubscribeBenefit(subscription.id);
        await context.dispatch('getSubscriptions');
        return Promise.resolve(data);
        // context.commit('setSubscriptions', data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  getters: {
    subscriptionsData(state) {
      return state.subscriptions;
    },
  }
}
